// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../img/x-office-document.svg";
import ___CSS_LOADER_URL_IMPORT_1___ from "../img/x-office-spreadsheet.svg";
import ___CSS_LOADER_URL_IMPORT_2___ from "../img/x-office-presentation.svg";
import ___CSS_LOADER_URL_IMPORT_3___ from "../img/x-office-drawing.svg";
import ___CSS_LOADER_URL_IMPORT_4___ from "../img/app-dark.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon-filetype-document{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.icon-filetype-spreadsheet{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.icon-filetype-presentation{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.icon-filetype-draw{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.icon-richdocuments{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");filter:var(--background-invert-if-dark)}", "",{"version":3,"sources":["webpack://css/filetypes.scss"],"names":[],"mappings":"AAAA,wBACC,wDAAA,CAGD,2BACC,wDAAA,CAGD,4BACC,wDAAA,CAGD,oBACC,wDAAA,CAGD,oBACC,wDAAA,CACA,uCAAA","sourcesContent":[".icon-filetype-document{\n\tbackground-image: url('../img/x-office-document.svg')\n}\n\n.icon-filetype-spreadsheet{\n\tbackground-image: url('../img/x-office-spreadsheet.svg');\n}\n\n.icon-filetype-presentation {\n\tbackground-image: url('../img/x-office-presentation.svg');\n}\n\n.icon-filetype-draw {\n\tbackground-image: url('../img/x-office-drawing.svg');\n}\n\n.icon-richdocuments {\n\tbackground-image: url(../img/app-dark.svg);\n\tfilter: var(--background-invert-if-dark);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
